<template>
  <div class="code-matching">
    <div class="code-matching-title">
      {{ userInfo.name ? userInfo.name + "-" : "" }}门店采购明细表
      <div class="back-account-center">
        <router-link to="/myAccount">
          <el-button type="link" icon="el-icon-arrow-left">返回个人中心</el-button>
        </router-link>
      </div>
    </div>
    <div class="code-matching-content">
      <div class="code-matching-main">
        <div class="search-content">
          <div class="wrapper">
            <el-form @keyup.enter.native="search">
              <div class="left">
                <el-input class="search-input" clearable v-model="searchForm.searchVal" placeholder="搜索商品名称"></el-input>
                <el-input class="search-manufacturer" clearable v-model="searchForm.manufacturer"
                  placeholder="搜索商品生产商家"></el-input>
                <!-- <el-tooltip
                effect="dark"
                content="请选择商品关联匹配状态"
                placement="top"
              >
                <el-select
                  v-model="searchForm.mapFlag"
                  placeholder="请选择匹配状态"
                >
                  <el-option
                    v-for="item in mapFlagOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option> </el-select
              ></el-tooltip> -->
                <el-input class="search-input" v-model="searchForm.barCode" clearable placeholder="搜索商品条形码"></el-input>
                <el-input class="search-manufacturer" clearable v-model="searchForm.buyOrderId"
                  placeholder="搜索采购单编号"></el-input>
                <el-input class="search-input" style="width: 250px" clearable v-model="searchForm.supplierNameTmp"
                  placeholder="搜索溯源供应商"></el-input>
                  <el-input class="search-input" style="width: 250px" clearable v-model="searchForm.supplierName"
                  placeholder="搜索供应商"></el-input>
                <el-tooltip effect="dark" content="请选择采购日期" placement="top">
                  <el-date-picker value-format="yyyy-MM-dd" style="margin-left: 10px" v-model="searchForm.buyTime"
                    clearable type="date" placeholder="请选择采购日期">
                  </el-date-picker>
                  ></el-tooltip>
                <el-button class="search-btn" type="primary" icon="el-icon-search" @click="search">搜索采购单</el-button>
                <el-button class="search-btn" type="danger" icon="el-icon-reload"
                  @click="resetSearch">重置搜索条件</el-button>
              </div>
            </el-form>
          </div>
          <div class="wrapper" style="padding-top: 10px">
            <div class="right">
              <el-button @click="showExcelImportModal" type="primary" icon="el-icon-upload2">Excel导入采购单明细</el-button>
              <el-button @click="reloadChinaOrderData" icon="el-icon-refresh-right" type="danger"
                plain>手动同步数据采集</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-tabs style="padding: 0 30px" v-model="searchForm.group" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) of groupList" :key="index" :label="item.k1" :name="item.k1">
              <span slot="label">{{ item.k1 }} <span class="badge">{{ item.v1 }}</span></span>
              <el-table v-loading="loading" ref="multipleTable" :border="true" :stripe="true" :data="tableData"
                style="width: 100%" size="mini" height="calc(100vh - 358px)" highlight-current-row
                @current-change="tableCurrentChange" :current-row-key="buyOrderDataKey"
                @cell-dblclick="handleCellDblClick">
                <!-- <el-table-column
                  style="padding-left: 10px"
                  fixed="left"
                  type="selection"
                  width="55"
                ></el-table-column> -->
                <!-- <el-table-column width="80" prop="id" label="编码">
                </el-table-column> -->
                <el-table-column fixed="left" align="center" width="180" prop="buyOrderId" label="采购单号">
                </el-table-column>
                <el-table-column align="center" width="140" prop="statusName" label="状态">
                  <template v-if="scope.row.statusName && scope.row.statusName !== ''" slot-scope="scope">
                    <el-tag v-for="(item, index) of scope.row.statusName.split(',')" :key="index" style="
                        font-size: 12px;
                        border-radius: 10px;
                        padding: 0 8px;
                        cursor: pointer;
                      " :type="index % 2 ? 'success' : 'error'" size="mini" effect="dark">{{ item }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="buyTime" label="入库日期">
                </el-table-column>
                <el-table-column width="230" prop="supplierNameTmp" label="溯源供应商（双击单元格编辑）">
                  <template slot-scope="scope">
                    <div v-if="scope.row.status !== '-2'">
                      <span v-if="editableData !== scope.row">{{
                        scope.row.supplierNameTmp
                      }}</span>
                      <el-input v-else :ref="'input-' + scope.$index" v-model="scope.row.supplierNameTmp"
                        @blur="handleInputBlur(scope.row)" @keyup.enter.native="handleInputEnter(scope.row)"></el-input>

                    </div>
                    <div v-else>
                      <span>{{ scope.row.supplierNameTmp }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="230" prop="supplierName" label="供应商">
                </el-table-column>
                <el-table-column align="center" width="80" prop="count" label="采购品种">
                </el-table-column>
                <el-table-column align="center" width="80" prop="money" label="采购金额">
                </el-table-column>
                <el-table-column width="120" prop="auditRemark" label="审核备注">
                </el-table-column>
                <el-table-column width="120" align="center" prop="dataOkFlag" label="是否处理完成">
                  <template slot-scope="scope">
                    <el-tag style="
                        font-size: 12px;
                        border-radius: 10px;
                        padding: 0 8px;
                        cursor: pointer;
                      " :type="scope.row.dataOkFlag === '1' ? 'success' : 'danger'" size="mini" effect="dark">
                      {{ scope.row.dataOkFlag === '1' ? '已完成' : '未完成' }}</el-tag>
                  </template>
                </el-table-column>
                <el-table-column width="130" prop="liansuoBuyOrderId" label="连锁采购单编码">
                </el-table-column>
                <el-table-column width="130" prop="liansuoSaleOrderId" label="连锁销售单编码">
                </el-table-column>
                <el-table-column width="120" prop="buyUserName" label="采购员">
                </el-table-column>
                <el-table-column width="150" align="center" prop="buyImgPath" label="随货同行单照片">
                  <template slot-scope="scope">
                    <!-- <el-image v-if="scope.row.buyImgPath && scope.row.buyImgPath !== ''"
                      style="width: 100px; height: 100px" :src="scope.row.buyImgPath.split(',')[0]"
                      :preview-src-list="scope.row.buyImgPath.split(',')">
                    </el-image> -->
                    <div v-if="scope.row.buyImgPath && scope.row.buyImgPath !== ''">

                      <div v-for="(fileDetail, index2) in scope.row.buyImgPath.split(',')"
                        style="width: 74px; height: 74px; overflow: hidden; margin: 0 auto" :key="index2"
                        v-show="index2 === 0">
                        <div style="
                  width: 100%;
                  height: 74px;
                  position: relative;
                  padding: 8px;
                  border: 1px solid #d9d9d9;
                  border-radius: 4px;
                " @click="ImgPreview(scope.row.buyImgPath)">
                          <img style="max-height: 56px; max-width: 56px; width: auto; height: auto; cursor: zoom-in"
                            :src="fileDetail" />
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="230" align="center" fixed="right" label="操作区">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.status !== '-2'" @click.native.prevent="orderItemDetail(scope.row)"
                      type="text" size="small">
                      查看明细
                    </el-button>
                    <el-button style="color: #f56c6c;" v-if="scope.row.status === '-1'"
                      @click.native.prevent="againAudit(scope.row)" type="text" size="small">
                      重新提交
                    </el-button>
                    <el-button v-if="scope.row.status !== '-2'" @click.native.prevent="showGoodsImgModal(scope.row)"
                      type="text" size="small">
                      {{ (!scope.row.buyImgPath || scope.row.buyImgPath === '') ? '' : '重新' }}上传随货同行
                    </el-button>
                    <el-button style="color: #f56c6c;" @click.native.prevent="deleteRow(scope.row)" type="text"
                      size="small">
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <div class="bottom-page-content">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNo"
              :page-sizes="[20, 30, 40, 50]" layout=" sizes,prev,pager, next,total" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <add-goods-modal @uploadSuccess="uploadSuccess" ref="addGoodsModal" />

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <add-goods-image-modal @preViewGoodsImg="preViewGoodsImg" @submitGoodsImg="submitGoodsImg"
      ref="addGoodsImageModal" />
    <el-dialog v-loading="pullDataLoading" title="请选择数据同步时间段范围" :visible.sync="pullDataVisible">
      <div style="display:flex; justify-content: center;align-items: center; height: 100px">
        <el-date-picker v-model="pullDataTime" type="daterange" align="right" unlink-panels range-separator="~"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pullDataVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmPullData">同步数据</el-button>
      </span>
    </el-dialog>
    <el-dialog title="重新提交采购单" :visible.sync="auditDialogVisible" width="30%" center>
      <span>
        <el-input v-model="auditRemark" style="height: 80px;" type="textarea" placeholder="请填写审核说明">

        </el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="auditDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAuditOrder">提交审核</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteAction, downFile, getAction, putAction } from "../../api/manage";
import AddGoodsModal from "./modules/AddGoodsModal.vue";
import AddGoodsImageModal from "./modules/AddGoodsImageModal.vue";
import { getUserInfo } from "../../assets/js/common/common";

export default {
  components: { AddGoodsModal, AddGoodsImageModal },
  data() {
    return {
      pullDataVisible: false,
      pullDataLoading: false,
      auditDialogVisible: false,
      auditRemark: "",
      pullDataTime: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      groupList: [],
      loading: false,
      totalCount: 0,
      pageNo: 1,
      pageSize: 20,
      editableData: null,
      searchForm: {
        searchVal: "",
        group: "待门店处理",
        supplierName: '河北商保医药连锁有限公司'
      },
      mapFlagOptions: [
        { value: "", label: "全部" },
        { value: "1", label: "已匹配" },
        { value: "0", label: "未匹配" },
      ],
      mapTypeOptions: [
        { value: "", label: "全部" },
        { value: "手动", label: "手动匹配" },
        { value: "自动", label: "自动匹配" },
      ],
      inventoryFlagOptions: [
        { value: "", label: "全部" },
        { value: "1", label: "有库存" },
        { value: "0", label: "无库存" },
      ],
      tableData: [], // 门店商品列表
      multipleSelection: [],
      buyOrderData: null,
      dialogImageUrl: "",
      dialogVisible: false,
      uploadBuyOrderId: '',
      userInfo: {},
      buyOrderData: {},
      buyOrderDataKey: "id", // 假设每行数据都有一个唯一的 id 字段
      url: {
        list: "/liansuo/mendian/mendianBuyOrder/list",
        audit: "/liansuo/mendian/mendianBuyOrder/audit",
        delete: "/liansuo/mendian/mendianBuyOrder/delete",
        pullData: "/liansuo/mendian/mendianBuyOrder/pullData"
      },
    };
  },
  created() {
    let userInfo = getUserInfo();
    console.log("当前登录的用户信息为", userInfo);
    if (userInfo && userInfo !== "") {
      this.userInfo = userInfo;
    }
    let group = localStorage.getItem("buyOrderGroup");
    if (group && group !== "") {
      this.searchForm.group = group;
    } else {
      this.searchForm.group = '待门店处理';
    }
    this.loadData(1);
  },
  methods: {
    confirmAuditOrder() {
      // 关联商品
      let url = this.url.audit;
      let record = this.buyOrderData;
      let params = {
        buyOrderId: record.buyOrderId,
        buyType: record.buyType,
        remark: this.auditRemark
      };
      putAction(url, params).then((res) => {
        this.auditDialogVisible = false;
        console.log("关联商品返回的数据为", res);
        if (res.code === 200 && res.success) {
          this.$message({
            message: "已重新提交采购单数据！",
            offset: 400,
            duration: 1000,
            type: "success",
          });
          this.loadData(1);
        }
      });
    },
    async againAudit(record) {
      this.buyOrderData = record;
      this.auditRemark = '';
      this.auditDialogVisible = true;
    },
    async confirmPullData() {
      let pullDataTime = this.pullDataTime;
      console.log("获取当前同步时间段范围为", pullDataTime)
      if (pullDataTime.length > 0) {
        let url = this.url.pullData;
        let startTime = pullDataTime[0];
        let endTime = pullDataTime[1];
        let data = {
          startTime: startTime,
          endTime: endTime
        }
        this.pullDataLoading = true;
        let res = await getAction(url, data);
        this.pullDataVisible = false;
        this.pullDataLoading = false;
        if (res.code == 200 && res.success) {
          console.log("同步数据成功", res)
          this.$message({
            message: res.message,
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "请选择同步时间段范围",
          type: "warning",
        });
      }
    },
    // 查看采购明细
    orderItemDetail(records) {
      console.log("获取当前采购单信息为", records);
      this.$router.push({
        path: "/buyOrderImport/orderItemDetail",
        query: {
          companyId: records.companyId,
          buyOrderId: records.buyOrderId,
        }
      })
    },
    ImgPreview(buyImgPath) {
      const $viewer = this.$viewerApi({
        images: buyImgPath ? buyImgPath.split(',') : ''
      })
    },
    // 手动同步采集数据
    reloadChinaOrderData() {
      this.pullDataVisible = true;
      this.pullDataTime = [];
    },
    // 双击时触发
    handleCellDblClick(row, column, cell, event) {
      console.log("column.property ", column.property);
      if (column.property === "supplierNameTmp") {
        this.editableData = row;
        this.$nextTick(() => {
          const inputRef = "input-" + this.tableData.indexOf(row);
          console.log("inputRef ", inputRef);
          console.log("this.$refs ", this.$refs);
          const inputElement = this.$refs[inputRef][0];
          if (inputElement) {
            inputElement.focus(); // 聚焦输入框
          } else {
            console.error("Input element not found:", inputRef);
          }
        });
      }
    },
    handleInputBlur(row) {
      this.editableData = null; // 返回到静态显示状态
      this.saveRowSupplierNameTmp(row);
    },
    handleInputEnter(row) {
      // 按下回车键时保存更改
      this.editableData = null; // 返回到静态显示状态
      // this.saveRowSupplierNameTmp(row);
    },
    async saveRowSupplierNameTmp(row) {
      // 输入框失去焦点时保存更改
      console.log("当前输入框的值为", row);
      console.log("当前输入框的值为", row.supplierNameTmp);
      // 只有当输入框的值发生变化时才进行保存
      // 关联商品
      let url = "/liansuo/mendian/mendianBuyOrder/edit";
      let params = {
        buyOrderId: row.buyOrderId,
        buyType: row.buyType,
        supplierNameTmp: row.supplierNameTmp,
      };
      let res = await putAction(url, params);
      if (res.code === 200 && res.success) {
        this.loadData(1)
      }
      console.log("修改溯源供应商的信息为", res)
    },
    showGoodsImgModal(scopeRow) {
      this.uploadBuyOrderId = scopeRow.buyOrderId;
      this.$refs.addGoodsImageModal.show(scopeRow);
    },
    waitMatchList() {
      localStorage.setItem("group", "未匹配");
      this.openNewWindow("/chain/goodsList");
    },

    submitGoodsImg(goodsImgList) {
      console.log("当前上传的图片列表", goodsImgList)
      if (goodsImgList.length > 0) {
        let picUrl = goodsImgList.map(item => item.url).toString();
        console.log("该用户上传的图片地址为", picUrl)
        // 上传采购明细随货同行照片
        let url = "/liansuo/mendian/mendianBuyOrder/edit";
        let uploadBuyOrderId = this.uploadBuyOrderId;
        let params = {
          buyOrderId: uploadBuyOrderId,
          buyType: '采购',
          buyImgPath: picUrl
        };
        putAction(url, params).then(res => {
          if (res.success) {
            this.$message.success("上传成功！");
            this.$refs.addGoodsImageModal.handleClose();
            this.loadData(1);
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message.error("请上传图片！");
      }
    },
    preViewGoodsImg(imgUrl) {
      this.dialogImageUrl = imgUrl;
      this.dialogVisible = true;
    },
    openNewWindow(path, query) {
      const routeData = this.$router.resolve({ path: path, query: query });
      console.log("routeData+++++++++++", routeData);
      window.open(routeData.href, "_blank");
    },
    // addGoodsRoute() {
    //   this.$router.push({ path: "/chain/goodsDetail" });
    // },
    auditMatch(item) {
      this.$confirm("确定审批关联？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 关联商品
        let url = "/liansuo/productMendian/mendian/edit";
        let params = {
          id: item.id,
          status: "1",
        };
        putAction(url, params).then((res) => {
          console.log("关联商品返回的数据为", res);
          if (res.code === 200 && res.success) {
            this.$message({
              message: "审批成功！",
              offset: 400,
              duration: 1000,
              type: "success",
            });
            this.loadData(1);
          }
        });
        console.log("确认关联的数据为", params);
      });
    },
    handleClick(val) {
      let group = val.name;
      this.searchForm.group = group;
      localStorage.setItem("buyOrderGroup", group);
      console.log("当前选择的筛选状态", group);
      this.loadData(1);
    },
    exportExcel({ fileName = "门店商品导出表" }) {
      let url = "/liansuo/productMendian/mendian/exportXls";
      downFile(url, this.searchForm).then((data) => {
        console.log("导出数据", data);
        if (!data) {
          this.$message.warning("文件下载失败");
          return;
        }
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          window.navigator.msSaveBlob(new Blob([data]), fileName + ".xls");
        } else {
          let url = window.URL.createObjectURL(new Blob([data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName + ".xls");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); //下载完成移除元素
          window.URL.revokeObjectURL(url); //释放掉blob对象
        }
      });
    },
    batchDelete() {
      let seletecdRow = this.multipleSelection;
      let ids = [];
      seletecdRow.forEach((item) => {
        ids.push(item.id);
      });
      if (ids.length === 0) {
        this.$message({
          type: "warning",
          message: "请选择要删除的数据！",
        });
        return;
      }
      this.$confirm("确定要删除选中的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/liansuo/mendian/mendianBuyOrderItem/deleteBatch";
          let idsStr = ids.toString();
          deleteAction(url, {
            ids: idsStr,
          }).then((res) => {
            console.log("批量删除商品列表数据为", ids);
            if (res.code === 200 && res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadData(1);
            } else {
              this.$message({
                type: "error",
                message: res.message,
              });
            }
          });
          return;
        })
        .catch(() => {
          // this.$message({
          //   type: "error",
          //   message: "批量删除操作失败",
          // });
        });
    },
    tableCurrentChange(val) {
      this.buyOrderData = val;
    },
    // 点击关联匹配
    showMatchProductsModal(item) {
      this.$refs.matchProductsModal.showModal(item);
    },
    // 修改本地商品库数据
    editGoodsData(item) {
      // this.$router.push({
      //   path: "/chain/goodsDetail",
      //   query: { id: item.id, opreateType: "1" },
      // });
      this.openNewWindow("/chain/goodsDetail", {
        id: item.id,
        opreateType: "1",
      });
    },
    // 确认匹配
    confirmMatch() { },
    toggleSelection(rows) {
      console.log("点击选中的复选框事件", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.searchForm.group = "待门店处理";
      let group = localStorage.getItem("buyOrderGroup");
      console.log("group++++++++", group);
      if (group && group !== "") {
        localStorage.removeItem("buyOrderGroup");
      }
      this.loadData(1);
    },
    resetSearch() {
      this.searchForm = {
        searchVal: "",
        group: "待门店处理",
      };
      this.group = "1";
      this.loadData(1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let pageNo = this.pageNo;
      this.loadData(pageNo);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.loadData(val);
    },
    // 上传成功
    uploadSuccess() {
      this.loadData(1);
    },
    loadData(page) {
      let _this = this;
      this.loading = true;
      let url = this.url.list;
      let pageSize = this.pageSize;
      let data = {
        ...this.searchForm,
        pageNo: page,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.loading = false;
        if (res.code === 200 && res.success) {
          _this.groupList = res.result.groupList;
          _this.tableData = res.result.list.records;
          console.log("商品列表数据为", _this.tableData);
          _this.totalCount = res.result.list.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    showExcelImportModal() {
      this.$refs.addGoodsModal.showExcelImportModal();
    },
    deleteRow(record) {
      this.$confirm("该操作会删除该采购单数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            buyType: record.buyType,
            companyId: record.companyId,
            buyOrderId: record.buyOrderId
          };
          let url = "/liansuo/mendian/mendianBuyOrder/delete";
          deleteAction(url, params).then((res) => {
            if (res.code === 200 && res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadData(1);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "删除操作失败",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
}

* {
  font-size: 14px;
}

.code-matching {
  height: 100%;
}

.code-matching-title {
  display: flex;
  height: 60px;
  background-color: #24263d;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: bold;
  color: #ffffff;
  position: relative;

  .back-account-center {
    position: absolute;
    left: 20px;
  }
}

.dropdown-btn {
  color: #409eff !important;
  background: #ecf5ff !important;
}

.code-matching-content {
  padding: 10px 10px;
  background-color: #ecedf2;
  height: calc(100% - 60px);

  .search-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    padding: 20px;

    .wrapper {
      width: 100%;

      .left {
        width: 100%;

        .el-input {
          width: 180px;
          min-width: 150px;
          margin-bottom: 10px;

          &.search-manufacturer {
            width: 200px;
          }
        }

        ::v-deep .el-input__inner {
          border-radius: 5px !important;
        }

        .el-select {
          min-width: 100px;
          width: 150px;
          margin-left: 10px;
          margin-bottom: 10px;
        }

        .search-input {
          padding: 0 10px;
          border-radius: 5px;
        }

        .search-btn {
          margin-left: 10px;
          margin-bottom: 10px;
        }
      }

      .right {
        .el-button {
          margin-left: 10px;
          margin-bottom: 10px;
        }

        .dropdown-btn {
          color: #409eff !important;
          background: #ecf5ff !important;
          border-color: #409eff !important;

          &:hover {
            background-color: #409eff !important;
            color: #ffffff !important;
          }
        }
      }
    }
  }

  ::v-deep .el-table th.el-table__cell {
    overflow: hidden;
    user-select: none;
    background-color: #f6f7fa !important;
  }
}

.table-content {
  background-color: #ffffff;

  .bottom-page-content {
    padding: 20px;
    display: flex;
    justify-content: flex-end;

    .opreate-btn {
      display: flex;

      div {
        width: 30px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: justify;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    ::v-deep .el-pagination__total {
      line-height: 28px !important;
    }
  }
}

::v-deep .el-table-column--selection {
  .el-checkbox {
    padding-left: 10px;
  }
}

/* 全局样式文件，例如 main.css */
::-webkit-scrollbar {
  width: 6px;
  /* 滚动条宽度 */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* 滚动条轨道背景色 */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* 滚动条滑块背景色 */
  border-radius: 3px;
  /* 滑块圆角 */
}

.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* 滑块悬停时背景色 */
}

// ::v-deep .el-table__body-wrapper, ::v-deep .el-scrollbar__wrap {
//   &::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//   }
// }
::v-deep .el-input {
  .el-input__clear {
    padding-right: 15px !important;
  }
}

.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  /* 使其成为圆形 */
  padding: 2px 5px;
  margin-left: 5px;
  /* 距离标签的距离 */
  position: relative;
  top: -10px;
  /* 调整到合适的位置 */
  font-size: 12px;
}
</style>