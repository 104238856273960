<template>
  <el-dialog
    title="发票申请信息核对"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
  >
    <div class="invoice-modal">
      <div class="invoice-order-data invoice-container">
        <div class="invoice-label">
          <p class="label-text">订单编号：</p>
          <p class="label-content">
            {{ invoiceData.saleOrderId }}
            <i
              :data-clipboard-text="invoiceData.saleOrderId"
              title="点击复制订单编号"
              @click="onCopyText('el-icon-document-copy')"
              class="el-icon-document-copy"
            ></i>
          </p>
        </div>
        <div class="invoice-label">
          <p class="label-text">开票金额：</p>
          <p class="label-content invoice-money">
            <span>¥</span>{{ invoiceData.invoiceMoney }}
          </p>
        </div>
      </div>

      <div class="invoice-data invoice-container">
        <div class="invoice-label">
          <p class="label-text">发票类型：</p>
          <div class="label-content">
            <el-radio size="small" v-model="fpType" label="普票" border
              >增值税电子普通发票</el-radio
            >
            <el-radio size="small" v-model="fpType" label="专票" border
              >增值税电子专用发票</el-radio
            >
          </div>
        </div>
        <div class="invoice-label">
          <p class="label-text">发票抬头：</p>
          <p class="label-content" :class="{ warning: !cusCompanyData.name }">
            {{ cusCompanyData.name ? cusCompanyData.name : "暂无发票抬头数据" }}
          </p>
        </div>
        <div class="invoice-label">
          <p class="label-text">税号：</p>
          <p
            class="label-content"
            :class="{ warning: !cusCompanyData.creditCode }"
          >
            {{
              cusCompanyData.creditCode
                ? cusCompanyData.creditCode
                : "暂无发票税号数据"
            }}
          </p>
        </div>
        <div v-if="fpType == '专票'" class="invoice-label">
          <p class="label-text">开票地址：</p>
          <p
            class="label-content"
            :class="{ warning: !cusCompanyData.address }"
          >
            {{
              cusCompanyData.address
                ? cusCompanyData.address
                : "暂无开票地址数据"
            }}
          </p>
        </div>
        <div v-if="fpType == '专票'" class="invoice-label">
          <p class="label-text">联系电话：</p>
          <p class="label-content" :class="{ warning: !cusCompanyData.phone }">
            {{
              cusCompanyData.phone ? cusCompanyData.phone : "暂无联系电话数据"
            }}
          </p>
        </div>
        <div v-if="fpType == '专票'" class="invoice-label">
          <p class="label-text">开户银行：</p>
          <p
            class="label-content"
            :class="{ warning: !cusCompanyData.accBank }"
          >
            {{
              cusCompanyData.accBank
                ? cusCompanyData.accBank
                : "暂无开户银行数据"
            }}
          </p>
        </div>
        <div v-if="fpType == '专票'" class="invoice-label">
          <p class="label-text">银行卡号：</p>
          <p class="label-content" :class="{ warning: !cusCompanyData.accNo }">
            {{
              cusCompanyData.accNo ? cusCompanyData.accNo : "暂无银行卡号数据"
            }}
          </p>
        </div>
      </div>
      <p class="tips">
        温馨提示：请认真核对您的发票抬头信息，并确认已收到相关货品后且无售后需求后提交申请，如有问题请联系在线客服进行信息修改!
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitApply">提交申请</el-button>
      <el-button type="danger" @click="dialogVisible = false"
        >取消申请</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getAction, postAction } from "../../api/manage";
export default {
  name: "InvoiceModal",
  data() {
    return {
      reverse: true,
      fpType: "普票",
      dialogVisible: false,
      showHeaderStatus: false,
      invoiceData: {},
      cusCompanyData: {},
    };
  },
  computed: {
    ...mapGetters(["companyConfigData"]),
  },
  methods: {
    async getMyInvoiceAcc(companyId) {
      let url = "/customer/customer/getMyInvoiceAcc?companyId=" + companyId;
      let res = await getAction(url, {});
      if (res.code === 200 && res.success) {
        let cusCompanyData = res.result;
        if (res.result && res.result !== "") {
          this.cusCompanyData = cusCompanyData;
        }
      }
      console.log("当前返回的仓库开票信息为", res);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    open(invoiceData) {
      this.invoiceData = invoiceData;
      this.getMyInvoiceAcc(invoiceData.companyId);
      this.dialogVisible = true;
    },
    async submitApply() {
      let cusCompanyData = this.cusCompanyData;
      let invoiceData = this.invoiceData;
      let fpType = this.fpType;
      if (fpType === "普票") {
        if (!cusCompanyData.name || !cusCompanyData.creditCode) {
          this.$alert(
            "该客商开具发票信息不完整，请联系客服完善客商信息档案后再进行申请开具电子发票功能！",
            "提示消息：",
            { type: "warning" }
          );
          return false;
        }
      } else if (fpType === "专票") {
        if (
          !cusCompanyData.name ||
          !cusCompanyData.creditCode ||
          !cusCompanyData.address ||
          !cusCompanyData.phone ||
          !cusCompanyData.accBank ||
          !cusCompanyData.accNo
        ) {
          this.$alert(
            "该客商开具发票信息不完整，请联系客服完善客商信息档案后再进行申请开具电子发票功能！",
            "提示消息：",
            { type: "warning" }
          );
          return false;
        }
      }
      let url = `saleOrder/saleOrder/fpApply?saleOrderId=${invoiceData.saleOrderId}&fpType=${fpType}`;
      let res = await postAction(url, {});
      console.log("发票申请返回的数据为", res);
      this.dialogVisible = false;
      if (res.code === 200 && res.success) {
        this.$alert(
          "开具电子发票申请已提交，请稍后在订单详情中下载！",
          "提示消息",
          { type: "success" }
        );
      } else {
        this.$alert(res.message, "提示消息", { type: "error" });
      }
    },
    // 切换包裹操作
    changeLogisticsWaybillId() {},
  },
};
</script>

<style lang="scss" scoped>
.invoice-container {
  background: #f3f3f3;
  padding: 10px 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  .invoice-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0;
    font-size: 16px;
    .label-text,
    .label-content {
      font-size: 14px;
      &.warning {
        color: $act-color;
      }
      &.invoice-money {
        color: $act-color;
        font-weight: bold;
        span {
          margin-right: 2px;
        }
      }
    }
    .label-text {
      width: 100px;
    }
  }
}
::v-deep .el-radio.is-bordered.is-checked,
::v-deep .el-radio__inner:hover {
  border-color: $act-color;
}
.tips {
  margin-left: 10px;
  font-size: 13px;
  color: $act-color;
  span {
    font-size: 13px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
::v-deep .el-icon-close {
  font-size: 18px !important;
}

.el-icon-document-copy {
  cursor: pointer;
  &:hover {
    color: $act-color;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
  .el-button {
    margin: 0 30px;
    font-size: 14px;
  }
}
</style>