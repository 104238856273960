var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",attrs:{"id":"goodsImgBox"}},[_c('div',{staticClass:"container_box"},[_c('ul',{staticClass:"goods-list-content"},_vm._l((_vm.productList),function(item,index){return _c('li',{key:'imgProduct' + index,class:{
          'offline-sale-item':
            item.offlineSaleFlag && item.offlineSaleFlag == '1',
        }},[_c('div',{staticClass:"good-data-content"},[_c('div',{staticClass:"good-img-content"},[_c('router-link',{staticClass:"goods-pic-flex",attrs:{"to":{
                path: '/productDetail',
                query: { productId: item.productId },
              },"title":item.name,"target":"_blank"}},[(_vm.specialAreaMaskImg && _vm.specialAreaMaskImg !== '')?_c('img',{staticClass:"maskImg",attrs:{"src":_vm.specialAreaMaskImg,"alt":"产品遮罩图"}}):(
                  item.productLabelList &&
                  item.productLabelList.length > 0 &&
                  _vm.prolabelMaskImg(item.productLabelList) !== ''
                )?_c('img',{staticClass:"maskImg",attrs:{"src":_vm.prolabelMaskImg(item.productLabelList),"alt":"产品遮罩图"}}):_vm._e(),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.productImg(item)),expression:"productImg(item)"}],staticClass:"productImg"})])],1),(
              item.policyFlag &&
              item.productPolicyList &&
              item.productPolicyList.length > 0
            )?_c('img',{staticClass:"act-img-icon",attrs:{"src":require("@/assets/images/product/act-cx.png"),"alt":""}}):(_vm.specailFlagStatus(item))?_c('img',{staticClass:"act-img-icon",attrs:{"src":require("@/assets/images/product/act-tj.png"),"alt":""}}):_vm._e(),_c('price-show-text',{attrs:{"salesPrice":_vm.showSalePrice(item),"originalPrice":_vm.showOriginalPrice(item),"specailFlag":_vm.specailFlagStatus(item)}}),_c('div',{staticClass:"goods-name-content"},[(
                _vm.loginState &&
                item.inventoryList &&
                item.inventoryList.length > 0
              )?_c('span',{staticClass:"goods-tag-list"},[(item.inventoryList[0].deliveryTime)?_c('span',{staticClass:"tip2 zy-tag"},[_vm._v(_vm._s(item.inventoryList[0].deliveryTime))]):_vm._e(),(item.companyName && item.companyName !== '')?_c('span',{staticClass:"stock-tag",class:item.companyName === '河北仓' ? 'tip3' : 'tip2'},[_vm._v(_vm._s(item.companyName))]):_vm._e(),(item.medicalType && item.medicalType === '1')?_c('span',{staticClass:"yb-tag"},[_vm._v("医保")]):_vm._e()]):_vm._e(),_c('span',{staticClass:"goods-name"},[_vm._v(_vm._s(item.name)+" "+_vm._s(item.format))])]),_c('div',{staticClass:"factory",staticStyle:{"cursor":"pointer"},attrs:{"title":item.manufacturer},on:{"click":function($event){return _vm.searchFatory(item.manufacturer)}}},[_vm._v(" "+_vm._s(item.manufacturer && item.manufacturer.replace(/\s*/g, "") !== "" ? item.manufacturer : "暂无厂家信息！")+" ")]),(_vm.loginState)?_c('div',{staticClass:"validity"},[_vm._v(" 有效期: "+_vm._s(item.inventoryList && item.inventoryList.length > 0 && item.inventoryList[0].endDate && item.inventoryList[0].endDate !== "" ? item.inventoryList[0].endDate : "暂无效期")+" ")]):_c('div',{staticClass:"validity"},[_vm._v(" 有效期: "),_c('span',{staticStyle:{"color":"#fd2c17"}},[_vm._v("会员可见")])]),_c('div',{staticClass:"good-discount"},[(
                _vm.loginState &&
                item.inventoryList &&
                item.inventoryList.length > 0
              )?_c('p',{staticClass:"goods-tag-list"},[(
                  (item.productActivityPriceStrategyFlag !== 1 ||
                    item.productActivityPriceStrategyDetail.canSaleAmount ===
                      0) &&
                  item.minDiscountTicket &&
                  item.minDiscountTicket.name !== ''
                )?_c('span',{staticClass:"tip1"},[_vm._v(" "+_vm._s(item.minDiscountTicket.name)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"good-tags"},[(
                _vm.loginState &&
                item.inventoryList &&
                item.inventoryList.length > 0
              )?_c('div',{staticClass:"goods-tag-list"},[(
                  item.inventoryList[0].priceTitle &&
                  item.inventoryList[0].priceTitle !== ''
                )?_c('span',{staticClass:"tip2"},[_vm._v(_vm._s(item.inventoryList[0].priceTitle))]):_vm._e(),(
                  item.policyFlag &&
                  item.productPolicyList &&
                  item.productPolicyList.length > 0
                )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.productPolicyList[0].remark,"placement":"top"}},[_c('span',{staticClass:"tip1"},[_vm._v("促销")])]):_vm._e(),(item.inventoryList[0].nearDateFlag)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"该产品为近效期产品","placement":"top"}},[_c('span',{staticClass:"tip4"},[_vm._v("近效期")])]):_vm._e()],1):_vm._e()])],1),_c('transition',{attrs:{"name":"el-zoom-in-bottom"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCartActionIndex !== item.id),expression:"showCartActionIndex !== item.id"}],staticClass:"goods-bottom-act"},[_c('p',{staticClass:"inventory"},[(item.inventoryList && item.inventoryList.length > 0)?_c('span',{staticClass:"kucun"},[_vm._v(" 库存："+_vm._s(_vm.showInventoryText(item.inventoryNum, item))+" ")]):(_vm.loginState)?_c('span',{staticClass:"kucun"},[_vm._v("库存：暂无库存")]):_c('span',{staticClass:"kucun"},[_vm._v("库存："),_c('i',{staticStyle:{"font-style":"normal","color":"#fd2c17"}},[_vm._v("会员可见")])])]),_c('span',{staticClass:"cart-icon",on:{"click":function($event){_vm.showCartActionIndex = item.id}}},[_c('img',{attrs:{"src":require("@/assets/images/common/cart-white.png"),"alt":""}})])])]),_c('transition',{attrs:{"name":"el-zoom-in-bottom"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCartActionIndex === item.id),expression:"showCartActionIndex === item.id"}],staticClass:"cart-input-box transition-box"},[_c('div',{staticClass:"input-content"},[_c('i',{staticClass:"el-icon-minus",on:{"click":function($event){return _vm.lessNum(item.inventoryList[0], item)}}}),_c('input',{class:{
                  shoppingHas:
                    item.inventoryList[0].shoppingCartAmount &&
                    item.inventoryList[0].shoppingCartAmount > 0,
                },attrs:{"oninput":"value=value.replace(/[^0-9.]/g,'')","type":"text"},domProps:{"value":item.inventoryList[0].addCartQuantity},on:{"focus":function($event){return _vm.selectShopAmount($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.jionShopCart(item.inventoryList[0], item, $event)},"change":function($event){return _vm.changeNum(item.inventoryList[0], item, $event)}}}),_c('i',{staticClass:"el-icon-plus",on:{"click":function($event){return _vm.addNum(item.inventoryList[0], item)}}})]),_c('div',{staticClass:"cart-btn",style:(item.offlineSaleFlag &&
                item.offlineSaleFlag == '1' &&
                item.productNotputSaleCreateOrder == '0'
                  ? 'width: 100%'
                  : ''),on:{"click":function($event){return _vm.jionShopCart(item.inventoryList[0], item, $event)}}},[_vm._v(" "+_vm._s(item.offlineSaleFlag && item.offlineSaleFlag == "1" ? item.productNotputSaleCreateOrder == "1" ? "开票员订货" : "联系开票员" : "加入购物车")+" ")])])])],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }