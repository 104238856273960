<template>
  <div class="order-item-detail">
    <el-breadcrumb class="nav-to-list" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/myAccount' }">个人中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/buyOrderImport/orderItem' }">门店采购单</el-breadcrumb-item>
      <el-breadcrumb-item>采购单明细</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card header="随货同行单" style="height: calc(40vh - 50px);" class="box-card">
      <el-button class="upload-btn" @click="showGoodsImgModal" icon="el-icon-upload" type="danger">
        上传随货同行单
      </el-button>
      <div class="demo-image__preview">
        <div v-if="buyImgPathList.length > 0" class="buy-img-path">
          <div class="img-list">
            <div class="clear">
              <div v-for="(item, index) of buyImgPathList" :key="index"
                style="float: left; width: 104px; height: 104px; margin-right: 10px; margin: 0 8px 8px 0">
                <div class="preview-img" style="border-color: #d9d9d9">
                  <el-image style="width: auto; height: auto; max-width: 100%; max-height: 100%" :src="item"
                    :preview-src-list="buyImgPathList" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>
    </el-card>
    <el-card header="采购明细单">
      <el-table v-loading="loading" ref="multipleTable" :border="true" :stripe="true" :data="tableData"
        style="width: 100%;" height="calc(60vh - 110px)" size="mini" highlight-current-row @current-change="tableCurrentChange"
        :current-row-key="currentRowKey" @cell-dblclick="handleCellDblClick">
        <el-table-column align="center" width="150" prop="statusName" label="状态">
          <template v-if="scope.row.statusName !== ''" slot-scope="scope">
            <el-tag v-for="(item, index) of scope.row.statusName.split(',')" :key="index" style="
                        font-size: 12px;
                        border-radius: 10px;
                        padding: 0 8px;
                        cursor: pointer;
                      " :type="index % 2 ? 'success' : 'error'" size="mini" effect="dark">{{ item }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" prop="mendianProductId" label="商品编码"></el-table-column>
        <el-table-column width="160" prop="liansuoProductId" label="连锁商品编码"></el-table-column>
        <el-table-column width="150" prop="name" label="商品名称"></el-table-column>
        <el-table-column width="80" prop="format" label="商品规格"></el-table-column>
        <el-table-column width="240" prop="manufacturer" label="生产厂家"></el-table-column>
        <el-table-column width="80" prop="unit" label="单位"></el-table-column>
        <el-table-column align="center" width="80" prop="dosageForm" label="剂型"></el-table-column>
        <el-table-column align="center" width="140" prop="license" label="批准文号"></el-table-column>
        <el-table-column width="130" prop="barCode" label="条形码"></el-table-column>
        <el-table-column align="center" width="80" prop="price" label="采购单价"></el-table-column>
        <el-table-column align="center" width="80" prop="amount" label="采购数量"></el-table-column>
        <el-table-column align="center" width="80" prop="money" label="采购金额"></el-table-column>
        <el-table-column width="80" prop="batchNo" label="采购批号"></el-table-column>
        <el-table-column align="center" width="100" prop="madeTime" label="生产日期">
          <template slot-scope="scope">
            {{ scope.row.madeTime ? scope.row.madeTime.substring(0, 10) : '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="100" prop="availTime" label="有效期至">
          <template slot-scope="scope">
            {{ scope.row.availTime ? scope.row.availTime.substring(0, 10) : '' }}
          </template></el-table-column>
        <el-table-column width="230" align="center" fixed="right" label="操作区">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.liansuoProductId || scope.row.liansuoProductId === ''" style="color: #f56c6c;"
              @click.native.prevent="showMatchProductsModal(scope.row)" type="text" size="small">
              商品对码
            </el-button>
            <el-button v-if="!scope.row.liansuoProductId || scope.row.liansuoProductId === ''" style="color: #f56c6c;"
              @click.native.prevent="addLinasuoGoods(scope.row)" type="text" size="small">
              新增商品首营
            </el-button>
            <el-button style="color: #f56c6c;" @click.native.prevent="deleteRow(scope.row.id)" type="text" size="small">
              删除明细
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <add-goods-image-modal @preViewGoodsImg="preViewGoodsImg" @submitGoodsImg="submitGoodsImg"
      ref="addGoodsImageModal" />
    <match-products-modal ref="matchProductsModal" @refresh="loadData(1)" />
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>

</template>

<script>
import { deleteAction, getAction, postAction, putAction } from '../../api/manage';
import MatchProductsModal from '../chain/modules/MatchProductsModal.vue';
import AddGoodsImageModal from './modules/AddGoodsImageModal.vue';
export default {
  components: { AddGoodsImageModal, MatchProductsModal },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      loading: false,
      totalCount: 0,
      pageNo: 1,
      pageSize: 20,
      currentRowKey: 0,
      tableData: [],
      companyId: '',
      buyOrderId: '',
      searchForm: {
      },
      url: {
        list: "/liansuo/mendian/mendianBuyOrderItem/list1",
        delete: "/liansuo/mendian/mendianBuyOrderItem/delete"
      },
      uploadBuyOrder: {},
      buyImgPathList: [], // 获取采购单的图片地址
    }
  },
  created() {
    let querys = this.$route.query;
    if (querys.buyOrderId && querys.buyOrderId !== "") {
      this.companyId = querys.companyId;
      this.buyOrderId = querys.buyOrderId;
      // 获取采购单信息
      this.getBuyOrderData();
      // 获取采购单明细
      this.loadData(1);
    }
  },
  methods: {
    addLinasuoGoods(scopeRow) {
      let matchId = scopeRow.erpId;
      const routeData = this.$router.resolve({
        path: "/chain/goodsDetail",
        query: { opreateType: "2", matchId: matchId },
      });
      window.open(routeData.href, "_blank");
    },
    showGoodsImgModal() {
      let scopeRow = this.uploadBuyOrder;
      this.$refs.addGoodsImageModal.show(scopeRow);
    },
    getBuyOrderData() {
      let url = "/liansuo/mendian/mendianBuyOrder/getOne";
      let params = {
        buyOrderId: this.buyOrderId,
        buyType: '采购'
      };
      postAction(url, params).then((res) => {
        if (res.code === 200 && res.success) {
          let result = res.result;
          this.uploadBuyOrder = result;
          let buyImgPath = result.buyImgPath;
          // let buyImgPath = "https://yjh-shopping.oss-cn-beijing.aliyuncs.com/shoppingPic/54e6df213e1b4ffe9c4b4dc5288bd0eb.jpg,https://yjh-shopping.oss-cn-beijing.aliyuncs.com/shoppingPic/54e6df213e1b4ffe9c4b4dc5288bd0eb.jpg";
          console.log("获取当前采购单的随货同行地址数据为", buyImgPath)
          if (buyImgPath && buyImgPath !== "") {
            this.buyImgPathList = buyImgPath.split(',');
          }
        }
      })
    },
    submitGoodsImg(goodsImgList) {
      console.log("当前上传的图片列表", goodsImgList)
      if (goodsImgList.length > 0) {
        let picUrl = goodsImgList.map(item => item.url).toString();
        console.log("该用户上传的图片地址为", picUrl)
        // 上传采购明细随货同行照片
        let url = "/liansuo/mendian/mendianBuyOrder/edit";
        let params = {
          buyOrderId: this.buyOrderId,
          buyType: '采购',
          buyImgPath: picUrl
        };
        putAction(url, params).then(res => {
          if (res.success) {
            this.$message.success("上传成功！");
            this.$refs.addGoodsImageModal.handleClose();
            this.getBuyOrderData()
            this
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message.error("请上传图片！");
      }
    },
    preViewGoodsImg(imgUrl) {
      this.dialogImageUrl = imgUrl;
      this.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let pageNo = this.pageNo;
      this.loadData(pageNo);
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.loadData(val);
    },
    loadData(page) {
      let _this = this;
      this.loading = true;
      let url = this.url.list;
      let pageSize = this.pageSize;
      let data = {
        ...this.searchForm,
        buyOrderId: this.buyOrderId,
        companyId: this.companyId,
        buyType: "采购",
        pageNo: page,
        pageSize: pageSize,
      };
      getAction(url, data).then((res) => {
        _this.loading = false;
        if (res.code === 200 && res.success) {
          _this.tableData = res.result.records;
          console.log("商品列表数据为", _this.tableData);
          _this.totalCount = res.result.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    deleteRow(id) {
      let _this = this;
      this.$confirm("该操作会删除该采购单明细数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = _this.url.delete + "?id=" + id;
          deleteAction(url).then((res) => {
            if (res.code === 200 && res.success) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.loadData(1);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch((error) => {
          console.log("删除操作失败", error)
          this.$message({
            type: "error",
            message: "删除操作失败",
          });
        });
    },

    showMatchProductsModal(item) {
      item.id = item.erpId;
      this.$refs.matchProductsModal.showModal(item);
    },
    tableCurrentChange() {

    },
    handleCellDblClick() {

    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  position: relative;

  .upload-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px 20px;
  }
}

::v-deep .el-card__header {
  font-size: 17px;
  font-weight: 700;
}

.bottom-page-content {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.nav-to-list {
  height: 50px;
  padding: 20px;

  ::v-deep .el-breadcrumb__inner {
    font-size: 14px;
    font-weight: normal;
  }
}

::v-deep .el-empty {
  padding: 0;
}

::v-deep .el-pagination__total {
  line-height: 28px !important;
}

.img-list {
  padding-left: 20px;

  .preview-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d9d9d9;
    border-radius: 4px;
  }
}

.clear {
  width: 100%;
}

.clear::after {
  display: block;
  clear: both;
  content: '';
}
</style>